import React from "react"
import T from "./translator"
import Alert from "./alert"
import DRY from "./dry"
import { Box, Stack } from "@fastly/consistently"

import PartialLogStreamingDefaultFormat from "~partials/log-streaming-default-format.mdx"

// export const = () => {
//   return ()
// }

export const LimitedAvailability = () => {
  return (
    <Alert important>
      <T>limited-availability</T>
    </Alert>
  )
}

export const BetaAvailability = () => {
  return (
    <Alert important>
      <T>beta-availability</T>
    </Alert>
  )
}

export const NextGenWafPrevious = () => {
  return (
    <Alert>
      <small><DRY>next-gen-waf-previously-offered</DRY></small>
    </Alert>
  )
}

// cloud-security.html
export const CloudSecurity = () => {
  return (
    <Alert>
      <small><T>cloud-security</T></small>
    </Alert>
  )
}

// waf-2020-notice.html
export const Waf2020Notice = () => {
  return (
    <Alert important>
      <T>waf-eol-notice</T>
    </Alert>
  )
}

// product-purchase-details.html
export const ProductPurchaseDetails = () => {
  return (
    <Alert>
      <T>product-purchase-details</T>
    </Alert>
  )
}

// product-expiry-hours.html
export const ProductExpiryHours = () => {
  return (
    <Alert>
      <T>product-expiry-hours</T>
    </Alert>
  )
}

// log-streaming-default-format.html
export const LogStreamingDefaultFormat = () => {
  return (
    <PartialLogStreamingDefaultFormat />
  )
}

// outbound-data-transfer-discount.html
export const OutboundDataTransferDiscount = () => {
  return (
    <Alert note>
      <DRY>outbound-data-transfer-discount</DRY>
    </Alert>
  )
}

// translation-ja.html
export const TranslationJA = () => {
  return (
    <>
      <br/><span data-swiftype-index="false">翻訳についての<a href="/translations">注意事項</a></span>
    </>
  )
}

// simplified packaging billing notice
export const PackageBillingNotice = () => {
   return (
    <Alert note>
      <T>package-billing-notice</T>
    </Alert>
  )
}

// export const = () => {
//   return ()
// }
